import React from "react";

// STYLE
import "./taskCard.scss";

// COMPONENTS
import Heading from "../../atoms/Heading/Heading";

const TaskCard = ({ data }) => (
    <>
        <div className="task-card">
            <div className="task-card--content">
                <Heading as="h3" className="task-card--name">
                    {data.name.substring(0, 40)}
                    {data.name.length > 40 ? "..." : ""}
                </Heading>
            </div>
            <div className="task-card--background-image">
                <div className="gatsby-image-wrapper">
                    {data?.images[0]?.url ? (
                        <img
                            data-main-image
                            src={
                                process.env.STRAPI_API_URL +
                                data?.images[0]?.url
                            }
                            alt=""
                            style={{ opacity: 1 }}
                        />
                    ) : (
                        <div className="bg-light w-100 h-100"></div>
                    )}
                </div>
            </div>
        </div>
    </>
);

export default TaskCard;
