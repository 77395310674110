import React from "react";
import classnames from "classnames";
import { navigate } from "gatsby";

// STYLE
import "./routeCard.scss";

import Icon from "../../atoms/Icon/Icon";
import PlainText from "../../atoms/PlainText/PlainText";
import Tag from "../../atoms/Tag/Tag";
import Heading from "../../atoms/Heading/Heading";
import Button from "../../atoms/Button/Button";
import RouteCardTags from "../RouteCardTags/RouteCardTags";
import FavoriteButton from "../FavoriteButton/FavoriteButton";

const difficultyToClassName = (difficulty) =>
    difficulty === "Łatwy"
        ? "easy"
        : difficulty === "Średni"
        ? "medium"
        : difficulty === "Trudny"
        ? "hard"
        : undefined;

const RouteCard = ({ className, route, dragging, isRouteView }) => {
    return (
        <div
            className={classnames(
                `route-card route-card--${difficultyToClassName(
                    route?.difficulty?.name
                )}`,
                className
            )}
        >
            <button
                className="btn clickable-area"
                onClick={(e) => {
                    e.target.blur();
                    if (!dragging && document) {
                        if (isRouteView) {
                            document.body.classList.add("blink");
                            setTimeout(() => {
                                navigate(`/trasy/${route?.id}`);
                            }, 100);
                        } else {
                            navigate(`/trasy/${route?.id}`);
                        }
                    }
                }}
                aria-label={`Nazwa trasy: ${route?.name}`}
            ></button>
            <div className="route-card--top-container">
                <img
                    className="img-fluid"
                    src={process.env.STRAPI_API_URL + route?.thumb?.url}
                    alt=""
                />
                <FavoriteButton
                    route={route}
                    classes="btn--secondary__sm"
                    label="inside"
                />
                <Button
                    className="btn--secondary__sm label"
                    onClick={() => navigate(`/trasy/${route?.id}`)}
                >
                    <PlainText className="btn--label">
                        <Icon className="icon-eye-min" />
                    </PlainText>
                </Button>
                <Tag
                    className={classnames(
                        "tag",
                        `tag--${difficultyToClassName(route?.difficulty?.name)}`
                    )}
                >
                    {route?.difficulty?.name}
                </Tag>
            </div>
            <div className="route-card--content-container">
                <Heading className="h3" as="h3">
                    {route?.name}
                </Heading>
                <RouteCardTags route={route} />
            </div>
        </div>
    );
};

export default RouteCard;
