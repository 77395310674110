import React from "react";

// STYLE
import "./tag.scss";

// COMPONENTS
import Icon from "../Icon/Icon";

const Tag = (props) =>
    !props.group ? (
        <div {...props}>{props.children}</div>
    ) : (
        <div className="tag--group">
            {props.group.map((el, i) => (
                <div key={i} className="icon--with-label__dark">
                    {el.icon && <Icon className={el.icon} />}
                    <span className="icon--label">{el.text}</span>
                    <span className="sr-only">{el.text}</span>
                </div>
            ))}
        </div>
    );

export default Tag;
