import React from "react";
import Icon from "../../atoms/Icon/Icon";
import PlainText from "../../atoms/PlainText/PlainText";

export const RouteCardTags = ({ route }) => {
    return (
        <div className="route-card--tags">
            <div className="icon--with-label mt-3">
                <Icon className="icon-pin-min" />
                <PlainText className="icon--label">
                    {route.locations?.[0]?.name ?? route.location}
                </PlainText>
            </div>
            <div className="icon--with-label mt-3">
                <Icon className="icon-distance-min" />
                <PlainText className="icon--label">{`${(
                    route?.distance / 1000
                ).toFixed(1)} km`}</PlainText>
            </div>
        </div>
    );
};

export default RouteCardTags;
