import React from "react";
import { navigate } from "gatsby";

// STYLE
import "./pageHeader.scss";

// COMPONENTS

import { Container, Row, Col } from "reactstrap";

import Button from "../../atoms/Button/Button";
import Icon from "../../atoms/Icon/Icon";

const PageHeader = ({ bgImage, AdditionalIcon, children, backUrl }) => (
    <header
        className="page-header"
        style={
            bgImage && {
                background: `url(${bgImage.src}) no-repeat center center / cover`,
            }
        }
    >
        <Container>
            <Row>
                <Col>
                    <div className="page-header--actions">
                        <Button
                            className="btn--circle page-header--action-btn"
                            onClick={() => navigate(backUrl ? backUrl : -1)}
                            aria-label="Cofnij"
                        >
                            <Icon className="icon-chevron-left-min"></Icon>
                        </Button>
                        {AdditionalIcon && <AdditionalIcon />}
                    </div>
                </Col>
            </Row>
            {children}
        </Container>
    </header>
);

export default PageHeader;
