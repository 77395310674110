import React, { useState } from "react";
import { useDispatch } from "react-redux";

// STYLE
import "./routesSlider.scss";

// COMPONENTS

// ACTIONS
import { setCurrentRoute } from "../../../state/actions";

// COMPONENTS
import { Container, Row, Col } from "reactstrap";

import SlickSlider from "../../atoms/SlickSlider/SlickSlider";
import RouteCard from "../../molecules/RouteCard/RouteCard";
import useRoutes from "../../../hooks/redux/useRoutes";
import Button from "../../atoms/Button/Button";
import Icon from "../../atoms/Icon/Icon";

const NextButton = (props) => {
    const { onClick } = props;
    return (
        <Button
            className="slick--next btn--circle"
            onClick={onClick}
            aria-label="Następna trasa"
        >
            <Icon className="icon-arrow-back" />
        </Button>
    );
};

const PrevButton = (props) => {
    const { onClick } = props;
    return (
        <Button
            className="slick--prev btn--circle"
            onClick={onClick}
            aria-label="Poprzednia trasa"
        >
            <Icon className="icon-arrow-back" />
        </Button>
    );
};

const RouteSlider = ({
    sliderRef,
    centerMode = true,
    slidesToShow = 3,
    variableWidth = false,
    withHeading = false,
    routeId,
    isRouteView,
    position,
    mapRef,
}) => {
    const dispatch = useDispatch();
    const [{ routes }] = useRoutes();
    const [dragging, setDragging] = useState(false);

    const next = () => {
        sliderRef.current.slickNext();
    };

    const previous = () => {
        sliderRef.current.slickPrev();
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        variableWidth,
        arrows: false,
        afterChange: (current) => {
            setDragging(false);
            dispatch(setCurrentRoute(current));
        },
        beforeChange: () => setDragging(true),
    };

    const routesArr = routeId
        ? routes.filter((route) => route.id !== routeId)
        : routes;

    return (
        <>
            <div
                className={`routes-slider ${
                    position && "routes-slider--" + position
                }`}
            >
                {position ? (
                    <Container>
                        <Row>
                            <Col>
                                <div className="routes-slider--top">
                                    <Button
                                        className="btn btn-default btn--secondary__sm mb-0"
                                        withoutaction="true"
                                    >
                                        Wybierz trasę
                                    </Button>
                                    <div className="btn--group">
                                        <PrevButton onClick={previous} />
                                        <NextButton onClick={next} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                ) : (
                    <div className="routes-slider--top">
                        {withHeading && (
                            <h3 className="h3 mb-0">Trasy dla Ciebie</h3>
                        )}
                        <div className="btn--group">
                            <PrevButton onClick={previous} />
                            <NextButton onClick={next} />
                        </div>
                    </div>
                )}
                <Container
                    className={`${!position ? "single-route-view" : ""}`}
                >
                    <SlickSlider
                        className="routes-slider--container"
                        innerRef={sliderRef}
                        settings={{
                            ...settings,
                            infinite: false,
                        }}
                    >
                        {routesArr &&
                            routesArr.map((route, index) => (
                                <div
                                    className="routes-slider--item"
                                    key={index}
                                >
                                    <RouteCard
                                        route={route}
                                        dragging={dragging}
                                        isRouteView={isRouteView}
                                    />
                                </div>
                            ))}
                    </SlickSlider>
                </Container>
            </div>
        </>
    );
};

export default RouteSlider;
